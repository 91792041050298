@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth;
}

body {
  // @apply py-[79px];
  @apply pb-20;
}

.m-globals {
  @apply pt-10 sm:px-32 px-10;
}
